<div class="header">{{"about.digital-repository"|translate}}</div>
<div class="card shadow rounded-md">
    <p>{{"about.project-description"|translate}}</p>
</div>

<div class="header">{{"about.objectives.title"|translate}}</div>
<div class="card shadow rounded-md">
    <ul>
        <li>{{"about.objective.preserve-scientific-production"|translate}}</li>
        <li>{{"about.objective.preserve-theses"|translate}}</li>
        <li>{{"about.objective.knowledge-economy"|translate}}</li>
        <li>{{"about.objective.encourage-innovation"|translate}}</li>
        <li>{{"about.objective.intellectual-property"|translate}}</li>
        <li>{{"about.objective.reduce-waste"|translate}}</li>
        <li>{{"about.objective.prevent-plagiarism"|translate}}</li>
        <li>{{"about.objective.intellectual-security"|translate}}</li>
        <li>{{"about.objective.facilitate-access"|translate}}</li>
        <li>{{"about.objective.national-alternative"|translate}}</li>
        <li>{{"about.objective.repository-theses"|translate}}</li>
    </ul>
</div>