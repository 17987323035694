<footer class="text-lg-start d-flex flex-column flex-md-row">
  <!-- Copyright -->
  <div class="col-3">
    <img class="img-fluid" src="/assets/images/SDL-LOGO-BIG.png" alt="SDL-Logo">
  </div>
  <div class="col-6 d-flex">
    <!-- <ul class="footer-info list-unstyled d-flex justify-content-center mb-0">
      <li>
      </li>
      <li *ngIf="showPrivacyPolicy">
        <a class="btn " routerLink="info/privacy">{{ 'footer.link.privacy-policy' | translate}}</a>
      </li>
      <li *ngIf="showEndUserAgreement">
        <a class="btn " routerLink="info/end-user-agreement">{{ 'footer.link.end-user-agreement' | translate}}</a>
      </li>
      <li *ngIf="showSendFeedback$ | async">
        <a class="btn " routerLink="info/feedback">{{ 'footer.link.feedback' | translate}}</a>
      </li>
    </ul> -->
    <!-- "footer.link.share-ideas":"Share Ideas",

  "footer.SDL":"SDL",
  "footer.SDL.portal":"SDL Portal",
  "footer.knowledge-resources":"Knowledge Resource",
  "footer.help":"Help",
  "footer.FAQ":"FAQ",
  "footer.guides-and-instructions":"Guides and Instructions",
 -->
    <div>
      <h4>{{'footer.help'|translate}}</h4>
      <a>{{'footer.FAQ'|translate}}</a>
      <a>{{'footer.guides-and-instructions'|translate}}</a>
    </div>
    <div>
      <h4>{{'footer.SDL'|translate}}</h4>
      <a href="https://sdl.edu.sa">{{'footer.SDL.portal'|translate}}</a>
      <a href="https://sdl.edu.sa/SDLPortal/ar/A-ZArabic.aspx">{{'footer.knowledge-resources'|translate}}</a>
    </div>
  </div>
  <div class="col-3 d-flex">
    <button class="btn btn-link " type="button" (click)="showCookieSettings()">
      {{ 'footer.link.cookies' | translate}}
    </button>
    |
    <button class="btn btn-link " type="button" (click)="showCookieSettings()">
      {{ 'footer.link.feedback' | translate}}
    </button>
  </div>
</footer>

<!-- Copyright -->
<p class="p-2 text-center">
  {{ 'footer.copyright' | translate:{year: dateObj | date:'y'} }}
</p>